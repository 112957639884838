import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import {useLazyQuery} from '@apollo/client';
import { Box } from '@material-ui/core';
import { useLocation } from '@reach/router';
import {cloneDeep, pick} from 'lodash';
import qs from 'querystringify';

import Board from './Board';
import { TasksDashboardProvider } from './TasksDashboardContext';
import TasksDashboardFilter from './TasksDashboardFilter';
import { filterTasks } from './utils';
import { GET_TASKS_TODO } from '../../graphql/queries/tasksToDo';
import { Loader } from '../helpers';

TasksDashboard.propTypes = {
  setError: PropTypes.func,
};

export default function TasksDashboard({ setError }) {
  const [tasksToDo, setTasksTodo] = useState(null);
  const [fetchToDo, { data, error, loading, refetch }] = useLazyQuery(GET_TASKS_TODO)

  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    let newFilters = {};

    let { filters } = pick(parsedQuery, [
      'filters',
    ]);

    if (filters) {
      const jsonFilters = JSON.parse(filters) || {};

      if (jsonFilters?.assigneeId) {
        newFilters.assigneeId = jsonFilters.assigneeId.id;
      }

      if (jsonFilters?.creator) {
        newFilters.createdById = jsonFilters.creator.id;
      }
    }

    if (Object.keys(newFilters).length) {
      fetchToDo({ variables: { filters: newFilters}})
    } else {
      fetchToDo()
    }
  }, [location.search, parsedQuery, fetchToDo])

  useEffect(() => {
    const { q } = pick(parsedQuery, ['q']);
    if (data?.tasksToDo) {
      setTasksTodo(filterTasks(data.tasksToDo, q));
    }
  }, [location.search, parsedQuery, data]);

  useEffect(() => {
    if (data) {
      setTasksTodo(data.tasksToDo);
    }

    const { q } = pick(parsedQuery, ['q']);
    if (q && data?.tasksToDo) {
      setTasksTodo(filterTasks(data.tasksToDo, q));
    }
  }, [data, parsedQuery]);

  if (loading || (!error && !loading && !tasksToDo)) {
    return <Loader />;
  }

  if (error) {
    if (setError instanceof Function) {
      setError(error || null);
    }

    return null;
  }

const updateTasks = (tasks, type) => {
  const newTasks = cloneDeep(tasksToDo);
  newTasks[type] = tasks;
  setTasksTodo(newTasks);
}

  return (
    <Box sx={{ overflow: 'hidden', height: '100%', }}>
      <TasksDashboardFilter />
      <TasksDashboardProvider>
        <Box sx={{ display: 'flex', gap: 3, height: 'calc(100% - 58px)' }}>
          <Board tasks={tasksToDo?.past} type="past" {...{ refetch, updateTasks }} />
          <Board tasks={tasksToDo?.today} type="today" {...{ refetch, updateTasks }} />
          <Board tasks={tasksToDo?.tomorrow} type="tomorrow" {...{ refetch, updateTasks }} />
          <Board tasks={tasksToDo?.further} type="further" {...{ refetch, updateTasks }} />
        </Box>
      </TasksDashboardProvider>
    </Box>
  );
}