import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef, useState,
} from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import CloseIcon from "@material-ui/icons/Close";
import SearchOffIcon from '@material-ui/icons/SearchOff';
import TodayIcon from '@material-ui/icons/Today';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import dayjs from "dayjs";
import { cloneDeep, findIndex } from "lodash";
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import { TASKS_UPDATE } from 'modules/tasks/graphql/mutations/tasksUpdate';

import { getBoardStyles } from './Board.styles';
import Task from './Task';
import { TaskProvider } from './TaskContext';
import { TasksDashboardContext } from './TasksDashboardContext';
import { sortTasks } from './utils';
import { InlinePicker } from "../../../../components/DateTimeInlinePicker";
import { DATE_TIME_FORMAT } from "../../../../core/constants/Formats";
import { taskBoardColorMap } from '../../constants';

Board.propTypes = {
  refetch: PropTypes.func,
  tasks: PropTypes.array,
  type: PropTypes.oneOf(['past', 'today', 'tomorrow', 'further']),
  updateTasks: PropTypes.func,
};

export default function Board({ refetch, tasks, type, updateTasks }) {
  const { setDraggableTask, setUpdatingTask, state } = useContext(
    TasksDashboardContext,
  );

  const [ showModalEditDate, setShowModalEditDate ] = useState(false);
  const [ idFurther, setIdFurther ] = useState(null);
  const dateRef = useRef(null);

  const boardColor = useMemo(() => taskBoardColorMap[type], [type]);
  const boardIcon = useMemo(() => ({
    past: <AlarmIcon fontSize="small" />,
    today: <TodayIcon fontSize="small" />,
    tomorrow: <WatchLaterOutlinedIcon fontSize="small" />,
    further: <UpdateOutlinedIcon fontSize="small" />,
  }[type]), [type]);
  const boardRef = useRef(null);
  const boardStyles = useMemo(() => getBoardStyles(boardColor), [boardColor]);

  const sortedTasks = useMemo(() => sortTasks([...tasks]), [tasks]);

  const [update, { loading: updateInProgress }] = useMutation(
    TASKS_UPDATE,
    {
      onCompleted: (res) => {
        if (res.tasksUpdate && refetch instanceof Function) {
          refetch();
        }
      },
      onError: (error) => {
        console.log('🚀 ~ file: Board.jsx ~ line 63 ~ Board ~ error', error);
        alert(`Ошибка: Ошибка обновления задачи`);
      },
    },
  );

  const highlightBoard = useCallback((boardType) => {
    if (boardRef?.current) {
      boardRef.current.style.background = {
        today: 'linear-gradient(90deg, rgba(241,242,246,0.8802871490393032) 0%, rgba(228,237,232,1) 34%, rgba(129,199,132,0.2449930313922444) 84%)',
        tomorrow: 'linear-gradient(90deg, rgba(241,242,246,0.8802871490393032) 0%, rgba(228,237,232,1) 34%, rgba(23,113,230,0.2449930313922444) 84%)',
        further: 'linear-gradient(90deg, rgba(241,242,246,0.8802871490393032) 0%, rgba(228,237,232,1) 34%, rgba(109,107,105,0.2449930313922444) 84%)',
      }[boardType];
    }
  }, [boardRef]);

  const clearBoardHighlight = useCallback(() => {
    if (boardRef?.current) {
      boardRef.current.removeAttribute('style');
    }
  }, [boardRef]);

  const highlightTask = useCallback((task) => {
    const taskElement = document.getElementById(`task-${task.id}`);
    if (taskElement) {
      taskElement.style.boxShadow = '5px 7px 4px rgb(163 163 163 / 42%)';
    }
  }, []);

  const clearTaskHighlight = useCallback((task) => {
    const taskElement = document.getElementById(`task-${task.id}`);
    if (taskElement) {
      taskElement.removeAttribute('style');
    }
  }, []);

  const handleTaskDragStart = useCallback(
    (task) => (e) => setDraggableTask(task),
    [setDraggableTask],
  );

  const handleTaskDragEnd = useCallback((task) => (e) => {
    e.preventDefault();
    setDraggableTask(null);
    clearTaskHighlight(task);
  }, [setDraggableTask, clearTaskHighlight]);

  const handleTaskDragLeave = useCallback((task) => (e) => {
    e.preventDefault();
    clearTaskHighlight(task);
  }, [clearTaskHighlight]);

  const handleTaskDragOver = useCallback((boardType, task) => (e) => {
    e.preventDefault();
    highlightTask(boardType, task);
  }, [highlightTask]);

  const handleBoardDragOver = useCallback((boardType) => (e) => {
    e.preventDefault();

    if (boardType === 'today' || boardType === 'tomorrow' || boardType === 'further') {
      highlightBoard(boardType);
    }
  }, [highlightBoard]);

  const handleBoardDragLeave = useCallback((e) => {
    e.preventDefault();

    if (boardRef?.current) {
      const rect = boardRef.current.getBoundingClientRect();

      if (e.clientY < rect.top || e.clientY >= rect.bottom ||
        e.clientX <= rect.left || e.clientX >= rect.right) {
        clearBoardHighlight();
      }
    }
  }, [boardRef, clearBoardHighlight]);

  const handleDrop = useCallback((boardType) => (e) => {
    e.preventDefault();

    if (boardType === 'today' || boardType === 'tomorrow' || boardType === 'further') {
      clearBoardHighlight();

      const boardDate = new Date();
      const currentTaskDueAt = DateTime
        .fromISO(state?.currentDraggableTask?.dueAt);

      if (boardType === 'tomorrow') {
        boardDate.setDate(boardDate.getDate() + 1);
      }

      if (boardType === 'further') {
        if (state?.currentDraggableTask?.certainTime === false) {
          dateRef.current = dayjs().hour(9).minute(0).second(0);
        } else {
          dateRef.current = state?.currentDraggableTask?.dueAt || boardDate;
        }

        setIdFurther(state?.currentDraggableTask?.id);
        toggleModal();
      } else {
        if (state?.currentDraggableTask?.certainTime === false) {
          boardDate.setHours(
            9,
            0,
            0,
            0,
          );
        } else {
          boardDate.setHours(
            currentTaskDueAt.hour,
            currentTaskDueAt.minute,
            currentTaskDueAt.second,
            currentTaskDueAt.millisecond,
          );
        }

        boardDate.toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' });

        setUpdatingTask(state?.currentDraggableTask || null);

        update({
          variables: {
            taskId: state?.currentDraggableTask?.id || '',
            taskParams: {
              dueAt: boardDate.toISOString(),
              certainTime: true
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearBoardHighlight, state?.currentDraggableTask, setUpdatingTask, update]);

  const toggleModal = useCallback(() => { setShowModalEditDate(!showModalEditDate) }, [showModalEditDate])

  const boardDragHandlers = useMemo(() => {
    if (type === 'today' || type === 'tomorrow' || type === 'further') {
      return {
        onDragLeave: handleBoardDragLeave,
        onDragOver: handleBoardDragOver(type),
        onDrop: handleDrop(type),
      };
    }

    return {};
  }, [type, handleBoardDragOver, handleBoardDragLeave, handleDrop]);

  useEffect(() => {
    if (!updateInProgress) {
      setUpdatingTask(null);
    }
  }, [setUpdatingTask, updateInProgress]);

  const updateTaskNote = (idTask, note) => {
    const newTasks = cloneDeep(tasks);
    let indexTask = findIndex(newTasks, ['id', idTask]);
    newTasks[indexTask].target.notes.unshift(note);

    updateTasks(newTasks, type);
  }

  const updateTask = (idTask, detail_task) => {
    const newTasks = cloneDeep(tasks);
    let indexTask = findIndex(newTasks, ['id', idTask]);
    newTasks[indexTask] = detail_task;

    updateTasks(newTasks, type);
  }

  const updateDateTask = value => {
    let dueAt = value.dueAt;

    if (value.dueAt !== null && value.dueAt2 !== null && (typeof value.certainTime == "boolean" && value.certainTime)) {
      let taskTime;
      let taskDate;

      if (typeof value.dueAt === 'string') {
        taskDate = value.dueAt.substring(0, 10);
      } else {
        let date = dayjs(value.dueAt).utc(false).format(DATE_TIME_FORMAT);
        taskDate = date.substring(0, 10);
      }

      if (typeof value.dueAt2 === "string") {
        taskTime = value.dueAt2.substring(10, value.dueAt2.length);
      } else {
        let time = dayjs(value.dueAt2).utc(false).format(DATE_TIME_FORMAT);

        taskTime = time.substring(10, time.length);
      }

      dueAt = `${taskDate}${taskTime}`;
    }

    update({
      variables: {
        taskId: idFurther || '',
        taskParams: {
           dueAt,
           certainTime: value.certainTime,
        },
      },
    });

    toggleModal();
  }

  return (
    <Card {...boardDragHandlers} ref={boardRef} sx={boardStyles.board}>
      <Box sx={boardStyles.boardHeader}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {boardIcon}
          <Typography sx={boardStyles.boardTitle} variant="h6">
            <FormattedMessage id={`tasks.type.${type}`} />
          </Typography>
        </Box>
        <Typography sx={boardStyles.boardTaskCount}>
          {sortedTasks?.length}
        </Typography>
      </Box>

      <Box sx={boardStyles.boardContent}>
        {!!sortedTasks?.length ? sortedTasks?.map((task) => (
          <Box
            draggable
            id={`task-${task.id}`}
            key={task.id}
            onDragEnd={handleTaskDragEnd(task)}
            onDragLeave={handleTaskDragLeave(task)}
            onDragOver={
              type === 'today' || type === 'tomorrow'
                ? handleTaskDragOver(task)
                : undefined
            }
            onDragStart={handleTaskDragStart(task)}
            sx={{
              cursor: 'grab',
              position: 'relative',
              transition: '0.2s ease-in-out',
              ...(
                task.id === state?.currentDraggableTask?.id && !updateInProgress
                  ? {
                    boxShadow: (theme) => theme.shadows[3],
                    transform: 'translateY(-3px)',
                  }
                  : {}
              ),
            }}
          >
            <TaskProvider {...{ refetch, task, type, updateTaskNote, updateTask }}>
              <Task />
            </TaskProvider>
          </Box>
        )) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              height: 100,
              color: 'gray',
            }}
          >
            <SearchOffIcon fontSize="large" htmlColor="inherit" />
            Список задач пуст
          </Box>
        )}
      </Box>

      {showModalEditDate && dateRef.current && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={toggleModal}
          open={showModalEditDate}
          scroll="paper"
        >
          <DialogTitle
            disableTypography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Укажите дату задачи</Typography>

            <IconButton onClick={toggleModal}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent>
            <InlinePicker
              certainTime={true}
              hardTime={true}
              onChange={updateDateTask}
              value={dayjs(dateRef.current)}
            />
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
}