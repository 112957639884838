import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { FormattedMessage } from "react-intl";

import AutocompleteEntities from 'components/AutocompleteEntities';

import { AssignProcessContext } from './AssignProcessContext';
import { SOURCE_DOCUMENTS_ASSIGN_TO_DISPUTE } from "../../graphql/mutations/sourceDocumentsAssignToDispute";
import { SOURCE_DOCUMENTS_ASSIGN_TO_LOAN_SUPPLY } from '../../graphql/mutations/sourceDocumentsAssignToLoanSupply';
import { SOURCE_DOCUMENTS_ASSIGN_TO_SUPPLYING_ORDER } from "../../graphql/mutations/sourceDocumentsAssignToSupplyingOrder";
import { GET_DISPUTES } from "../../graphql/queries/disputes";
import { SOURCE_DOCUMENT_AVAILABLE_ORDERS_FOR_ASSIGN } from '../../graphql/queries/sourceDocumentAvailableOrdersForAssign';
import { SOURCE_DOCUMENT_SUPPLYING_ORDERS_FOR_ASSIGN } from "../../graphql/queries/sourceDocumentSupplyingOrdersForAssign";
import { CATEGORY_DOCUMENTS, DocumentRecognizingStatusMap, DocumentStatusMap } from '../constants';
import {
  AssignAmountField,
  AssignDateField,
  AssignDocumentNumberField,
  AssignReceivingDateField,
} from '../helpers';
import OtherDocuments from '../OtherDocuments';
import Supplies from '../Supplies';

AssignForm.propTypes = {
  documentStatus: PropTypes.string,
  onOrderIdChange: PropTypes.func,
  onSessionClose: PropTypes.func,
  onSessionForceClose: PropTypes.func,
  orderId: PropTypes.string,
};

export default function AssignForm({
   documentStatus,
   onOrderIdChange,
   onSessionClose,
   onSessionForceClose,
   orderId
 }) {

  const { assignedObject, supplies } = useContext(AssignProcessContext);

  const [assignDocumentToLoanSupply, { assigningDocumentToLoanSupply }] = useMutation(
    SOURCE_DOCUMENTS_ASSIGN_TO_LOAN_SUPPLY,
    { onCompleted: () => onSessionForceClose() },
  );

  const [assignDocumentToDispute, { assigningDocumentToDispute }] = useMutation(
    SOURCE_DOCUMENTS_ASSIGN_TO_DISPUTE,
    { onCompleted: () => onSessionForceClose() },
  );

  const [assignDocumentToSupplyingOrder, { assigningDocumentToSupplyingOrder }] = useMutation(
    SOURCE_DOCUMENTS_ASSIGN_TO_SUPPLYING_ORDER,
    { onCompleted: () => onSessionForceClose() },
  );

  const [ordersLoanAvailableFetch, { data: dataLoanAvailableOrders, loading: fetchingLoanAvailableOrders }] = useLazyQuery(SOURCE_DOCUMENT_AVAILABLE_ORDERS_FOR_ASSIGN, {
    fetchPolicy: 'network-only',
  });
  const [ordersLoanSupplyingFetch, { data: dataLoanSupplyingOrders, loading: fetchingLoanSupplyingOrders }] = useLazyQuery(SOURCE_DOCUMENT_SUPPLYING_ORDERS_FOR_ASSIGN, {
    fetchPolicy: 'network-only',
  });

  const [ordersDisputesFetch, { data: dataDisputesOrders, loading: fetchingDisputesOrders }] = useLazyQuery(GET_DISPUTES, {
    fetchPolicy: 'network-only',
  });

  const [valueSupplier, setValueSupplier] = useState(null);
  const [valueLessee, setValueLessee] = useState(null);
  const [valueOrder, setValueOrder] = useState(null);
  const [valueDocumentNumber, setValueDocumentNumber] = useState(null);
  const [valueAmountField, setValueAmountField] = useState(null);
  const [valueDateAssign, setValueDateAssign] = useState(null);
  const [valueDateReceiving, setValueDateReceiving] = useState(null);
  const [valueDatePlanningField, setValuePlanningField] = useState(null);
  const [valueComment, setValueComment] = useState(null);

  const [formContext, setFormContext] = useState({});
  const [formData, setFormData] = useState({});

  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    const cloneFormData = cloneDeep(formData);

    if (assignedObject.form.schema?.properties?.supplier) {
      if (documentStatus === DocumentStatusMap.pending) {
        if (assignedObject?.recognizingProcess?.status === DocumentRecognizingStatusMap.succeed) {
          cloneFormData["supplier"] = assignedObject?.recognizingProcess?.ocrData?.seller.id;
          setValueSupplier(assignedObject?.recognizingProcess?.ocrData?.seller);
        } else {
          cloneFormData["supplier"] = null;
          setValueSupplier(null);
        }
      } else {
        cloneFormData["supplier"] = assignedObject?.recognizingProcess?.ocrData?.seller?.legalNameWithShortEntityType || '-';
        setValueSupplier(assignedObject?.recognizingProcess?.ocrData?.seller?.legalNameWithShortEntityType || '-');
      }
    }

    if (assignedObject.form.schema?.properties?.docNumber) {
      cloneFormData["docNumber"] = assignedObject?.recognizingProcess?.ocrData?.documentNumber || '';
      setValueDocumentNumber(assignedObject?.recognizingProcess?.ocrData?.documentNumber);
    }

    if (assignedObject.form.schema?.properties?.docDate) {
      cloneFormData["docDate"] = assignedObject?.recognizingProcess?.ocrData?.date;
      setValueDateAssign(assignedObject?.recognizingProcess?.ocrData?.date);
    }

    if (assignedObject.form.schema?.properties?.amount) {
      cloneFormData["amount"] = assignedObject?.recognizingProcess?.ocrData?.totalAmount || 0;
      setValueAmountField(assignedObject?.recognizingProcess?.ocrData?.totalAmount);
    }

    if (assignedObject.form.schema?.properties?.comment?.value) {
      cloneFormData["comment"] = assignedObject.form.schema.properties.comment.value || '';
      setValueComment(assignedObject.form.schema.properties.comment.value)
    }

    if (assignedObject.form.schema?.properties?.receivingDate?.value) {
      cloneFormData["receivingDate"] = assignedObject.form.schema.properties.receivingDate.value;
      setValueDateReceiving(assignedObject.form.schema.properties.receivingDate.value);
    }

    setFormData(cloneFormData);
    setLoadingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (valueSupplier?.id && valueLessee?.id) {
      let variables = {
        supplierId: valueSupplier.id,
        lesseeId: valueLessee.id
      }

      if (assignedObject.type.category === CATEGORY_DOCUMENTS.loanSupply) {
        ordersLoanAvailableFetch({ variables });
      }

      if (assignedObject.type.category === CATEGORY_DOCUMENTS.applicationSupplyingOrder) {
        ordersLoanSupplyingFetch({ variables });
      }
    }

    if (assignedObject.type.category === CATEGORY_DOCUMENTS.dispute && valueLessee?.id) {
      ordersDisputesFetch ({
        variables: {
          leasingEntityId: valueLessee.id
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSupplier, valueLessee]);

  useEffect(() => {
    const cloneFormContext = cloneDeep(formContext);

    let orders = [];

    if (dataLoanAvailableOrders?.sourceDocumentAvailableOrdersForAssign?.length) {
        orders = dataLoanAvailableOrders?.sourceDocumentAvailableOrdersForAssign;
      }

    if (dataLoanSupplyingOrders?.sourceDocumentSupplyingOrdersForAssign?.length) {
      orders = dataLoanSupplyingOrders?.sourceDocumentSupplyingOrdersForAssign;
    }

    cloneFormContext["orders"] = orders
    setFormContext(cloneFormContext)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoanAvailableOrders, dataLoanSupplyingOrders]);

  useEffect(() => {
    if (dataDisputesOrders?.disputes?.length) {
      const cloneFormContext = cloneDeep(formContext);

      cloneFormContext["disputes"] = dataDisputesOrders.disputes;

      setFormContext(cloneFormContext)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDisputesOrders]);

  useEffect(() => {
    if (supplies) {
      const cloneFormContext = cloneDeep(formContext);

      cloneFormContext["supplies"] = supplies;

      setFormContext(cloneFormContext)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplies]);

  const [otherDocuments, setOtherDocuments] = useState([]);
  const handleOtherDocumentsChange = useCallback(
    (value) => setOtherDocuments(value),
    [],
  );

  const AutocompleteEntitiesSupplierField = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange: onChangeProps, readonly, required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = value => {
      setValueSupplier(value);

      const cloneFormContext = cloneDeep(formContext);
      cloneFormContext["supplier"] = value.id;
      setFormContext(cloneFormContext)

      const cloneFormData = cloneDeep(formData);
      cloneFormData["supplier"] = value.id;
      setFormData(cloneFormData);

      onChangeProps(value.id);
    }

    if (documentStatus === DocumentStatusMap.pending) {
      return (
        <AutocompleteEntities
          disabled={readonly}
          label={title}
          onChange={onChange}
          required={required}
          value={valueSupplier}
        />
      );
    } else {
      return (
        <TextField
          InputProps={{ sx: { bgcolor: grey[50] } }}
          defaultValue={valueSupplier}
          disabled
          label={title}
          variant="outlined"
        />
      )
    }
  };

  const AutocompleteEntitiesLesseeField = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange: onChangeProps, readonly, required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = value => {
      setValueLessee(value);

      formData["lessee"] = value.id;

      onChangeProps(value.id);
    }

    return (
      <AutocompleteEntities
        disabled={readonly}
        label={title}
        onChange={onChange}
        required={required}
        value={valueLessee}
      />
    )
  };

  const AutocompleteEntitiesOrderFieldToLoanSupplier = props => {
    // eslint-disable-next-line react/prop-types
    const { formContext, onChange: onChangeProps, readonly, required, schema } = props;
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    const onChange = (_, value) => {
      onOrderIdChange(value?.id || '');

      setValueOrder(value);

      const cloneFormData = cloneDeep(formData);
      cloneFormData["order"] = value?.id
      setFormData(cloneFormData);

      onChangeProps(value?.id)
    }

    const fetchLoading = fetchingLoanAvailableOrders || fetchingLoanSupplyingOrders;

    return (
      <Autocomplete
        autoComplete
        clearText="Очистить"
        closeText="Скрыть список"
        disabled={readonly}
        fullWidth
        getOptionLabel={(option) => option.displayNameForSourceDocumentsAssign}
        loading={fetchLoading}
        noOptionsText={fetchLoading ? 'Поиск...' : 'Нет данных'}
        onChange={onChange}
        openText="Показать список"
        //eslint-disable-next-line react/prop-types
        options={formContext?.orders || []}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {fetchLoading
                    ? <CircularProgress color="inherit" size={20} />
                    : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={title}
            required={required}
          />
        )}
        value={valueOrder}
      />
    )
    //eslint-disable-next-line react/prop-types
  };

  const AutocompleteEntitiesOrderFieldToDispute = props => {
    // eslint-disable-next-line react/prop-types
    const { formContext, onChange: onChangeProps, readonly, required, schema } = props;
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    const onChange = (_, value) => {
      onOrderIdChange(value?.id || '');

      setValueOrder(value);

      const cloneFormData = cloneDeep(formData);
      cloneFormData["disputeID"] = value?.id
      setFormData(cloneFormData);

      onChangeProps(value?.id)
    }

    return (
      <Autocomplete
        autoComplete
        clearText="Очистить"
        closeText="Скрыть список"
        disabled={readonly}
        fullWidth
        getOptionLabel={(option) => option.number }
        loading={fetchingDisputesOrders}
        noOptionsText={fetchingDisputesOrders ? 'Поиск...' : 'Нет данных'}
        onChange={onChange}
        openText="Показать список"
        //eslint-disable-next-line react/prop-types
        options={formContext?.disputes || []}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {fetchingDisputesOrders
                    ? <CircularProgress color="inherit" size={20} />
                    : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={title}
            required={required}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              Дело №{option?.number}, тип - <FormattedMessage id={`incomingdocuments.disputeType.${option.type}`} />
            </li>
          )
        }}
        value={valueOrder}
      />
    )
    //eslint-disable-next-line react/prop-types
  };

  const DocumentNumberField = props => {
    // eslint-disable-next-line react/prop-types
    const { required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    const onChange = useCallback((value) => {
      setValueDocumentNumber(value);
      formData["docNumber"] = value?.toString() || '';
    }, []);

    return (
      <AssignDocumentNumberField
        documentNumber={valueDocumentNumber}
        documentStatus={documentStatus}
        onChange={onChange}
        required={required}
        title={title}
      />
    )
  }

  const AmountField = props => {
    // eslint-disable-next-line react/prop-types
    const { required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = useCallback((value) => {
      setValueAmountField(value);
      formData["amount"] = Number(value);
    }, []);

    return (
      <AssignAmountField
        amount={valueAmountField}
        documentStatus={documentStatus}
        onChange={onChange}
        required={required}
        title={title}
      />
    )
  }

  const DateAssignField = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange: onChangeProps, required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = value => {
      if (value) {
        setValueDateAssign(value);

        formData["docDate"] = value;

        onChangeProps(value);
      }
    }

    return (
      <AssignDateField
        date={valueDateAssign}
        documentStatus={documentStatus}
        onChange={onChange}
        required={required}
        title={title}
      />
    )
  }

  const DateReceivingField = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange: onChangeProps, required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = value => {
      if (value) {
        setValueDateReceiving(value);

        formData["receivingDate"] = value;

        onChangeProps(value);
      }
    }

    return (
      <AssignReceivingDateField
        date={valueDateReceiving}
        onChange={onChange}
        required={required}
        title={title}
      />
    )
  }

  const DateNotifiedBySupplierField = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange: onChangeProps, required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema

    const onChange = value => {
      if (value) {
        setValuePlanningField(value);

        formData["notifiedBySupplierAt"] = value;

        onChangeProps(value);
      }
    }

    return (
      <AssignReceivingDateField
        date={valueDatePlanningField}
        onChange={onChange}
        required={required}
        title={title}
      />
    )
  }

  const ShipmentTableField = props => {
    // eslint-disable-next-line react/prop-types
    const { schema } = props;
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    if (orderId) {
      return <Supplies title={title} {...{supplies}} />
    }

    return false;
  };

  const FilesUploadField = props => {
    // eslint-disable-next-line react/prop-types
    const { schema } = props;
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    if (orderId) {
      return <OtherDocuments onChange={handleOtherDocumentsChange} title={title}/>
    }

    return false;
  };

  const CommentField = props => {
    // eslint-disable-next-line react/prop-types
    const { required, schema } = props
    // eslint-disable-next-line react/prop-types
    const { title } = schema;

    const onChange = event => {
      setValueComment(event.target.value);
    }

    return (
      <TextField
        defaultValue={valueComment}
        label={title}
        minRows={4}
        multiline={true}
        onChange={onChange}
        required={required}
      />
    )
  };

  const customFields  = {
    'AutocompleteEntitiesSupplierField': AutocompleteEntitiesSupplierField, //Поставщик
    'AutocompleteEntitiesLesseeField': AutocompleteEntitiesLesseeField, //Лизингополучатель
    'AutocompleteEntitiesOrderField': AutocompleteEntitiesOrderFieldToLoanSupplier, //Заказ для ОТГРУЗОЧНЫЕ ДОКУМЕНТЫ и ЗАКАЗ
    'AutocompleteEntitiesOrderFieldToDispute': AutocompleteEntitiesOrderFieldToDispute, //Заказ для ДЕЛО
    'DocumentNumberField': DocumentNumberField,
    'AmountField': AmountField,
    'DateAssignField': DateAssignField,
    'DateReceivingField': DateReceivingField,
    'DateNotifiedBySupplierField': DateNotifiedBySupplierField, // Планируемая дата поставки
    'ShipmentTableField': ShipmentTableField,
    'FilesUploadField': FilesUploadField,
    'CommentField': CommentField,
  };

  const onSubmit = ({ formData }) => {
    let variables = {};

    if (assignedObject.type.category === CATEGORY_DOCUMENTS.loanSupply) {
      variables = {
        number: valueDocumentNumber,
        orderId: formData.order,
        sourceDocumentId: assignedObject?.id,
      };

      if (valueComment) {
        variables = {
          ...variables,
          comment: valueComment
        }
      }

      if (formData?.receivingDate) {
        variables = {
          ...variables,
          receivingDate: dayjs(formData.receivingDate).format('YYYY-MM-DD'),
        }
      }

      if (documentStatus === DocumentStatusMap.pending) {
        variables = {
          ...variables,
          amount : Number(valueAmountField),
          date: dayjs(formData.docDate).format('YYYY-MM-DD'),
        }
      }

      if (!!otherDocuments.length) {
        variables = { ...variables, otherDocuments };
      }

      assignDocumentToLoanSupply({
        variables: {
          assignParams: variables
        }
      });
    }

    if (assignedObject.type.category === CATEGORY_DOCUMENTS.dispute) {
      variables = {
        date: dayjs(formData.docDate).format('YYYY-MM-DD'),
        number: valueDocumentNumber,
        sourceDocumentId: assignedObject?.id,
        disputeId: formData.disputeID
      }

      if (valueComment) {
        variables = {
          ...variables,
          comment: valueComment
        }
      }

      if (valueAmountField) {
        variables = {
          ...variables,
          amount: Number(valueAmountField)
        }
      }

      if (formData?.receivingDate) {
        variables = {
          ...variables,
          receivingDate: dayjs(formData.receivingDate).format('YYYY-MM-DD'),
        }
      }

      assignDocumentToDispute({
        variables: {
          assignParams: variables
        }
      })
    }

    if (assignedObject.type.category === CATEGORY_DOCUMENTS.applicationSupplyingOrder) {
      variables = {
        supplyingOrderId: formData.order,
        sourceDocumentId: assignedObject?.id,
      };

      if (valueComment) {
        variables = {
          ...variables,
          comment: valueComment
        }
      }

      if (formData?.receivingDate) {
        variables = {
          ...variables,
          receivingDate: dayjs(formData.receivingDate).format('YYYY-MM-DD'),
        }
      }

      if (formData?.notifiedBySupplierAt) {
        variables = {
          ...variables,
          notifiedBySupplierAt: formData.notifiedBySupplierAt,
        }
      }

      assignDocumentToSupplyingOrder({
        variables: {
          assignParams: variables
        }
      })
    }
  }

  const transformErrors = errors => {
    return errors.map(error => {
      if (error?.name === "required" && error?.property) {
        return Object.assign({}, error, {
          message: `Данное поле обязательно`
        });
      }

      if (error?.name === "type" && error?.property) {
        return Object.assign({}, error, {
          message: `Неправильный тип`
        });
      }

      return error;
    });
  };

  const loadingProcess = () => {
    return assigningDocumentToLoanSupply || assigningDocumentToDispute || assigningDocumentToSupplyingOrder
  }

  return (
    <>
      <Typography
        component="h2"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        variant="h2"
      >
        { loadingProcess() ? 'Разносим...' : 'Разнесение входящих документов'}
        <CloseIcon onClick={onSessionClose} sx={{ cursor: 'pointer' }} />
      </Typography>

      {loadingData ? (
        <Box sx={{ height: "160px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Form
          fields={customFields}
          formContext={formContext}
          formData={formData}
          onChange={({formData}) => setFormData({...formData})}
          onSubmit={onSubmit}
          schema={assignedObject.form.schema}
          showErrorList={false}
          transformErrors={transformErrors}
          uiSchema={assignedObject.form.uiSchema}
          validator={validator}
        >
          <Button
            disableElevation
            disabled={loadingProcess()}
            sx={{ color: 'white', width: '100%'}}
            type={"submit"}
            variant="contained"
          >
            Разнести
          </Button>
        </Form>
      )}
    </>
  );
}