import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';

import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import MenuIcon from '@material-ui/icons/Menu';

import ErrorAlert from 'components/ErrorAlert';

import TasksFilter from '../components/TasksFilter';
import { TasksFilterProvider } from '../TasksFilterContext';

TasksLayout.propTypes = {
  children: PropTypes.node,
  onChangeView: PropTypes.func,
  view: PropTypes.oneOf(['line', 'table']),
};

export default function TasksLayout({ children, onChangeView, view }) {
  const [error, setError] = useState(null);

  const childrenWithErrorHandler = useMemo(
    () => React.Children.map(children, (child) => {
      return React.isValidElement(child)
        ? React.cloneElement(child, { setError })
        : child;
    }),
    [children],
  );

  const handleChangeView = useCallback((value) => () => {
    if (onChangeView instanceof Function) {
      onChangeView(value);
    }
  }, [onChangeView]);

  return (
    <Box
      sx={{
        mb: 8,
        p: 3,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <TasksFilterProvider>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
          <Typography gutterBottom variant="h2">Задачи</Typography>
        </Box>
        <AppBar color="default" position="static">
          <Toolbar>
            <Tooltip title="МОЙ ДАШБОРД">
              <IconButton
                color={view === 'line' ? 'primary' : 'default'}
                onClick={handleChangeView('line')}
              >
                <BarChartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="СПИСОК ЗАДАЧ">
              <IconButton
                color={view === 'table' ? 'primary' : 'default'}
                onClick={handleChangeView('table')}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" sx={{ mx: 3 }} />

            {view === 'table' && (
              <TasksFilter type={"table"} />
            )}
            {view === 'line' && (
              <TasksFilter type={"dashboard"} />
            )}
          </Toolbar>
        </AppBar>
        <Box sx={{ py: 3 }}>
          {error && (
            <ErrorAlert
              message={error.message}
              title="Ошибка при выполнении запроса"
            />
          )}
        </Box>
        {childrenWithErrorHandler}
      </TasksFilterProvider>
    </Box>
  );
}