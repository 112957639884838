import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { DevTool } from '@hookform/devtools';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Toolbar,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import _noop from 'lodash.noop';
import { Controller, useForm } from 'react-hook-form';

import { formatDate } from 'utils/dateTimeFormatters';

import {
  SUPPLYING_ORDERS_CREATE_SPECIFICATION,
} from './graphql/mutations/supplyingOrdersCreateSpecification';

const useStyles = makeStyles((theme) => ({
  root: {
    '& input': {
      fontWeight: 500,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
    '& textarea': {
      fontWeight: 500,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
  },
  focused: {
    backgroundColor: '#fff',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto 1fr',
    gridColumnGap: '16px',
    alignItems: 'center',
  },
}));

const filter = createFilterOptions();

const OPF_NAMES = [
  { full: 'Общество с ограниченной ответственностью', short: 'ООО' },
  { full: 'Индивидуальный предприниматель', short: 'ИП' },
  { full: 'Публичное акционерное общество', short: 'ПАО' },
  { full: 'Непубличное акционерное общество', short: 'НАО' },
  { full: 'Закрытое акционерное общество', short: 'ЗАО' },
  { full: 'Акционерное общество', short: 'АО' },
  { full: 'Федеральное государственное унитарное предприятие', short: 'ФГУП' },
  { full: 'Общественная организация', short: 'ОО' },
  { full: 'Частное учреждение', short: 'ЧУ' },
  { full: 'Федеральное государственное бюджетное учреждение', short: 'ФГБУ' },
  { full: 'Религиозная организация', short: 'РО' },
  { full: 'Крестьянское (фермерское) хозяйство', short: 'КФХ' },
  { full: 'Глава крестьянского (фермерского) хозяйства', short: 'ГКФХ' },
  { full: 'Муниципальное автономное учреждение', short: 'МАУ' },
  { full: 'Общественный фонд', short: 'ОФ' },
  { full: 'Муниципальное унитарное предприятие', short: 'МУП' },
  { full: 'Муниципальное бюджетное учреждение', short: 'МБУ' },
  { full: 'Автономная некоммерческая организация', short: 'АНО' },
];

const POSITIONS = [{
  nominative: 'Генеральный директор',
  genitive: 'Генерального директора',
}];
const BASED_ON_VARIANTS = [
  { variant: 'Устава' },
  { variant: 'Доверенности № ' },
];

const outlineFieldStyleProps = {
  variant: 'outlined',
  color: 'secondary',
  fullWidth: true,
  margin: 'dense',
};

FormSpec.propTypes = {
  defaultValues: PropTypes.object,
  frameAgreements: PropTypes.array,
  idForFrameAgres: PropTypes.string,
  onSuccess: PropTypes.func,
  supplyingOrderId: PropTypes.string,
};

export default function FormSpec({
  defaultValues,
  frameAgreements,
  idForFrameAgres,
  onSuccess = _noop,
  supplyingOrderId,
}) {
  const {
    control, errors, formState, handleSubmit, register, setError, setValue,
  } = useForm({ defaultValues });

  const [
    generateDoc,
    { data, loading },
  ] = useMutation(SUPPLYING_ORDERS_CREATE_SPECIFICATION);

  async function onSubmit({ annexDate, specDate, ...data }) {
    try {
      await generateDoc({
        variables: {
          supplyingOrderId,
          attributes: {
            ...data,
            annexDate: annexDate ? formatDate(annexDate, 'full') : '',
            specDate: specDate ? formatDate(specDate, 'full') : '',
          },
        },
      });
    } catch (error) {
      setError('FORM_ERROR', {
        message: error.message,
      });
    }
  }

  const classes = useStyles();

  const annexNumField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="annexNum"
      placeholder="1"
    />
  );

  const annexDateField = (
    <Controller
      control={control}
      defaultValue={null}
      name="annexDate"
      render={(props) => (
        <KeyboardDatePicker
          {...outlineFieldStyleProps}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disableToolbar
          format="dd/MM/yyyy"
          inputRef={register}
          inputVariant="outlined"
          // eslint-disable-next-line react/prop-types
          onChange={(date) => props.onChange(date)}
          placeholder="25/03/21"
          // eslint-disable-next-line react/prop-types
          value={props.value}
          variant="inline"
        />
      )}
    />
  );

  const agreementField = (
    <Autocomplete
      freeSolo
      getOptionLabel={(option) => option?.title || 'Заголовок отсутствует'}
      handleHomeEndKeys
      options={frameAgreements}
      renderInput={params => {
        return (
          <TextField
            {...params}
            {...outlineFieldStyleProps}
            inputRef={register}
            name="agreement"
            placeholder="18/0604/1-ОП от 4 июня 2020 г."
            required={true}
          />
        )
      }}
      renderOption={element => {
        return (
          <li key={element.id}>
            {element?.title || 'Заголовок отсутствует'}
          </li>
        );
      }}
    />
  );

  const specNumField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="specNum"
      placeholder="1"
    />
  );

  const specDateField = (
    <Controller
      control={control}
      defaultValue={null}
      name="specDate"
      render={(props) => (
        <KeyboardDatePicker
          {...outlineFieldStyleProps}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disableToolbar
          format="dd/MM/yyyy"
          inputRef={register}
          inputVariant="outlined"
          // eslint-disable-next-line react/prop-types
          onChange={(date) => props.onChange(date)}
          placeholder="25/01/21"
          // eslint-disable-next-line react/prop-types
          value={props.value}
          variant="inline"
        />
      )}
    />
  );

  const invoiceField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="invoice"
      placeholder="1"
    />
  );

  const businessEntityFullField = (
    <AutocompleteFreeSolo
      onChange={(newValue) => {
        setValue('businessEntityShort', newValue?.short || null);
      }}
      options={OPF_NAMES}
      renderInput={(params) => (
        <TextField
          {...params}
          {...outlineFieldStyleProps}
          inputRef={register}
          name="businessEntityFull"
          placeholder="Общество с ограниченной ответственностью"
        />
      )}
      value={defaultValues?.businessEntityFull}
      valueKey="full"
    />
  );

  const businessEntityShortField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="businessEntityShort"
      placeholder="ООО"
    />
  );

  const vendorNameFullField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="vendorNameFull"
      placeholder="Мобильные ТелеСистемы"
    />
  );

  const vendorNameShortField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="vendorNameShort"
      placeholder="МТС"
    />
  );

  const vendorINNnum = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="vendorINNnum"
      placeholder="1234567890"
    />
  );

  const vendorInChargePositionGenitiveField = (
    <AutocompleteFreeSolo
      onChange={(newValue) => {
        setValue('vendorInChargePosition', newValue?.nominative || '');
      }}
      options={POSITIONS}
      renderInput={(params) => (
        <TextField
          {...params}
          {...outlineFieldStyleProps}
          inputRef={register}
          name="vendorInChargePositionGenitive"
          placeholder="Генерального директора"
        />
      )}
      value={defaultValues?.vendorInChargePositionGenitive}
      valueKey="genitive"
    />
  );

  const vendorInChargePositionField = (
    <TextField
      {...outlineFieldStyleProps}
      InputLabelProps={{ shrink: true }}
      inputRef={register}
      label="Должность в именительном падеже"
      name="vendorInChargePosition"
      placeholder="Генеральный директор"
    />
  );

  const vendorInChargeFullNameField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="vendorInChargeFullName"
      placeholder="Петрова Ивана Николаевича"
    />
  );

  const vendorInChargeShortNameField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="vendorInChargeShortName"
      placeholder="И.О. Фамилия"
    />
  );

  const basedOnField = (
    <AutocompleteFreeSolo
      options={BASED_ON_VARIANTS}
      renderInput={(params) => (
        <TextField
          {...params}
          {...outlineFieldStyleProps}
          inputRef={register}
          name="basedOn"
          placeholder="Устава (Доверенности, ...)"
        />
      )}
      valueKey="variant"
    />
  );

  const paymentTermsField = (
    <TextField
      color="secondary"
      fullWidth
      inputRef={register}
      margin="normal"
      multiline
      name="paymentTerms"
      placeholder="- 100% предоплата"
      rowsMax={4}
    />
  );

  const deliveryAddressField = (
    <TextField
      color="secondary"
      fullWidth
      inputRef={register}
      margin="normal"
      multiline
      name="deliveryAddress"
      placeholder="г.Москва, ул.Рябиновая, 10 стр.4."
      rowsMax={4}
    />
  );

  const deliveryFrameField = (
    <TextField
      color="secondary"
      fullWidth
      inputRef={register}
      margin="normal"
      multiline
      name="deliveryFrame"
      placeholder="поставка производится в течение 3 (трех) рабочих дней с момента поступления платежа на расчетный счет Поставщика, при условии наличия Товара на складе, а также при получении извещения от Покупателя о готовности к принятию Товара."
      rowsMax={4}
    />
  );

  const leaseholderBusinessEntityFullField = (
    <AutocompleteFreeSolo
      onChange={(newValue) => {
        setValue('leaseholderBusinessEntityShort', newValue?.short || null);
      }}
      options={OPF_NAMES}
      renderInput={(params) => (
        <TextField
          {...params}
          {...outlineFieldStyleProps}
          inputRef={register}
          name="leaseholderBusinessEntityFull"
          placeholder="Общество с ограниченной ответственностью"
        />
      )}
      value={defaultValues?.leaseholderBusinessEntityFull}
      valueKey="full"
    />
  );

  const leaseholderBusinessEntityShortField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="leaseholderBusinessEntityShort"
      placeholder="ООО"
    />
  );

  const leaseholderNameFullField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="leaseholderNameFull"
      placeholder="Мобильные ТелеСистемы"
    />
  );

  const leaseholderNameShortField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="leaseholderNameShort"
      placeholder="МТС"
    />
  );

  const leaseholderINNnumField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      label="ИНН Лизингополучателя"
      name="leaseholderINNnum"
      placeholder="183112660364"
    />
  );

  const leaseholderOGRNnumField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      label="ОГРН Лизингополучателя"
      name="leaseholderOGRNnum"
      placeholder="309183107600012"
    />
  );

  const leaseholderLegalAddressField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      label="Юридический адрес"
      name="leaseholderLegalAddress"
      placeholder="Юридический адрес"
    />
  );

  const leaseholderFactAddressField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      label="Адрес местонахождения"
      name="leaseholderFactAddress"
      placeholder="Адрес местонахождения"
    />
  );

  return (
    <>
      <Box
        className={classes.root}
        component="form"
        maxWidth={1024}
        minWidth={720}
        onSubmit={handleSubmit(onSubmit)}
        width={1}
      >
        <Toolbar disableGutters>
          <Button
            color="primary"
            disableElevation
            disabled={loading}
            type="submit"
            variant="outlined"
          >
            Создать документ
          </Button>
          {formState.isSubmitSuccessful && (
            <Box
              component="a"
              download
              href={data?.supplyingOrdersCreateSpecification.document.assetUrl}
              p={2}
            >
              Скачать документ
            </Box>
          )}
        </Toolbar>
        {errors['FORM_ERROR'] && (
          <Alert severity="error">
            <AlertTitle>
              <Box mt={1}>Произошла ошибка при генерации документа</Box>
            </AlertTitle>
            <Typography variant="body2">
              Мы уже знаем об этом и скоро исправим
            </Typography>
          </Alert>
        )}
        <Box className={classes.grid} mb={8}>
          <Box gridColumn="1" gridRow="1">
            <Typography>Приложение №</Typography>
          </Box>
          <Box gridColumn="2" gridRow="1">
            {annexNumField}
          </Box>
          <Box gridColumn="3" gridRow="1">
            <Typography>от</Typography>
          </Box>
          <Box gridColumn="4" gridRow="1">
            {annexDateField}
          </Box>
          <Box gridArea="2/1">
            <Typography>К договору поставки №</Typography>
          </Box>
          <Box gridArea="2/2/2/5">
            {agreementField}
            <Link href={`${process.env.REACT_APP_HOST}/entities/${idForFrameAgres}/contracts`} target="_blank">Добавить договор</Link>
          </Box>
          <Box gridArea="3/1">
            <Typography>СПЕЦИФИКАЦИЯ №</Typography>
          </Box>
          <Box gridArea="3/2">{specNumField}</Box>
          <Box gridArea="3/3">
            <Typography>от</Typography>
          </Box>
          <Box gridArea="3/4">{specDateField}</Box>
          <Box gridArea="4/1">
            <Typography>К счёту №</Typography>
          </Box>
          <Box gridArea="4/2/4/5">{invoiceField}</Box>
        </Box>
        <Box mb={3}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Наименование ОПФ Поставщика (полное и краткое)
          </Typography>
          <Box display="flex">
            <Box flex="2" mr={3}>
              {businessEntityFullField}
            </Box>
            <Box flex="1">{businessEntityShortField}</Box>
          </Box>
        </Box>
        <Box mb={3}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Наименование организации Поставщика (полное и краткое)
          </Typography>
          <Box display="flex">
            <Box flex="2" mr={3}>
              {vendorNameFullField}
            </Box>
            <Box flex="1">{vendorNameShortField}</Box>
          </Box>
        </Box>
        <Box mb={3}>
          <Typography color="textSecondary" variant="h5">
            ИНН поставщика
          </Typography>
          {vendorINNnum}
        </Box>
        <Box mb={3}>
          <Typography gutterBottom variant="h5">
            В лице
          </Typography>
          <Box alignItems="center" display="flex">
            <Box flex="1" mr={3}>
              {vendorInChargePositionGenitiveField}
            </Box>
            <Box flex="1">{vendorInChargePositionField}</Box>
          </Box>
          <Box alignItems="center" display="flex">
            <Box flex="1" mr={3}>
              {vendorInChargeFullNameField}
            </Box>
            <Box flex="1">{vendorInChargeShortNameField}</Box>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" mb={8}>
          <Typography>действующего на основании</Typography>
          <Box flex="1" ml={3}>
            {basedOnField}
          </Box>
        </Box>
        <Box mb={3}>
          <Typography variant="h5">Условия оплаты</Typography>
          {paymentTermsField}
        </Box>
        <Box mb={3}>
          <Typography variant="h5">Адрес места приемки Товара</Typography>
          {deliveryAddressField}
        </Box>
        <Box mb={8}>
          <Typography variant="h5">Срок поставки</Typography>
          {deliveryFrameField}
        </Box>
        <Box mb={8}>
          <Box mb={3}>
            <Typography color="textSecondary" gutterBottom variant="h5">
              Наименование ОПФ Лизингополучателя (полное и краткое)
            </Typography>
            <Box display="flex">
              <Box flex="2" mr={3}>
                {leaseholderBusinessEntityFullField}
              </Box>
              <Box flex="1">{leaseholderBusinessEntityShortField}</Box>
            </Box>
          </Box>
          <Box mb={3}>
            <Typography color="textSecondary" gutterBottom variant="h5">
              Наименование организации Лизингополучателя (полное и краткое)
            </Typography>
            <Box display="flex">
              <Box flex="2" mr={3}>
                {leaseholderNameFullField}
              </Box>
              <Box flex="1">{leaseholderNameShortField}</Box>
            </Box>
          </Box>
          <Box display="flex">
            {leaseholderINNnumField}
            <Box mx={2} />
            {leaseholderOGRNnumField}
          </Box>
          {leaseholderLegalAddressField}
          {leaseholderFactAddressField}
        </Box>
      </Box>
      <DevTool control={control} />
    </>
  );
}

AutocompleteFreeSolo.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  renderInput: PropTypes.func,
  value: PropTypes.string,
  valueKey: PropTypes.string,
};

function AutocompleteFreeSolo({
  onChange = _noop,
  options,
  renderInput,
  value,
  valueKey,
}) {
  const [_value, setAutocompleteValue] = useState(value || null);

  function filterOptions(options, params) {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        [valueKey]: `Добавить "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  function getOptionLabel(option) {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[valueKey];
  }

  function renderOption(option) {
    return option[valueKey];
  }

  function handleChange(event, newValue) {
    if (typeof newValue === 'string') {
      setAutocompleteValue({
        [valueKey]: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutocompleteValue({
        [valueKey]: newValue.inputValue,
      });
    } else {
      setAutocompleteValue(newValue);
      onChange(newValue);
    }
  }

  return (
    <Autocomplete
      filterOptions={filterOptions}
      freeSolo
      getOptionLabel={getOptionLabel}
      handleHomeEndKeys
      onChange={handleChange}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      selectOnFocus
      value={_value}
    />
  );
}