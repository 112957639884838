import PropTypes from 'prop-types';
import React from 'react';

import { Box, Card, CardContent, Chip, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import CopiedText from 'components/CopiedText';
import HelpTooltip from 'components/HelpTooltip';

import { FinmonitoringOperationParticipantProps, FinmonitoringProblemProps } from './types';
import Phone from "../../../../components/Phone";
import { NoDataMessage } from '../../components/helpers';

const styles = {
  table: {
    mt: 4,
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
  tableKey: {
    color: '#91919c',
    fontWeight: 500,
    pr: 2,
    width: 0.35,
    flex: 'none',
  },
  tableValue: {
    textAlign: 'right',
    width: 0.65,
  },
};

FinmonitoringOperationParticipant.propTypes = {
  participant: FinmonitoringOperationParticipantProps,
  participantProblems: PropTypes.arrayOf(FinmonitoringProblemProps),
};

const Field = ({ problem, value }) => {
  if (problem) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Box sx={{ '&, & .noData': { color: '#ff1744', fontWeight: 500 } }}>{value}</Box>
        <HelpTooltip
          title={
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontSize: 14 }}>
                <FormattedMessage id={`finmonitoring.problem.context.${problem.context}`} />
              </Box>
              <Box sx={{ fontSize: 12 }}>{problem.messages.join('<br />')}</Box>
            </Box>
          }
        />
      </Box>
    );
  }

  return value;
};

Field.propTypes = {
  problem: FinmonitoringProblemProps,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function FinmonitoringOperationParticipant({ participant, participantProblems }) {
  return (
    <Card sx={{ width: 'calc(50% - 12px)' }}>
      <CardContent sx={{ p: 3 }}>
        <Typography sx={{ textAlign: 'center' }} variant="subtitle2">
          <Chip
            label={
              <FormattedMessage
                id={`finmonitoring.operationParticipant.role.${participant?.role}`}
              />
            }
            size="small"
            sx={{ fontWeight: 500, borderRadius: '4px', px: 1 }}
          />
          <br />
          <FormattedMessage
            id={`finmonitoring.operationParticipant.organizationalForm.${participant?.organizationalForm}`}
          />{' '}
          {'"'}
          {participant?.legalName}
          {'"'}
        </Typography>

        <Box sx={styles.table}>
          <Box sx={styles.tableRow}>
            <Typography sx={styles.tableKey} variant="body2">
              ИНН / КПП
            </Typography>
            <Typography sx={styles.tableValue} variant="subtitle2">
              <Field
                problem={
                  !!participantProblems.length
                    ? participantProblems.find((problem) => problem.fieldName === 'tin')
                    : undefined
                }
                value={
                  participant?.tin ? (
                    <CopiedText
                      feedback="ИНН скопирован!"
                      text={participant.tin}
                      title="Скопировать ИНН"
                    />
                  ) : (
                    <NoDataMessage />
                  )
                }
              />{' '}
              /{' '}
              <Field
                problem={
                  !!participantProblems.length
                    ? participantProblems.find((problem) => problem.fieldName === 'trrc')
                    : undefined
                }
                value={
                  participant?.trrc ? (
                    <CopiedText
                      feedback="КПП скопирован!"
                      text={participant.trrc}
                      title="Скопировать КПП"
                    />
                  ) : (
                    <NoDataMessage />
                  )
                }
              />
            </Typography>
          </Box>

          <Box sx={styles.tableRow}>
            <Typography sx={styles.tableKey} variant="body2">
              ОГРН
            </Typography>
            <Typography sx={styles.tableValue} variant="subtitle2">
              <Field
                problem={
                  !!participantProblems.length
                    ? participantProblems.find((problem) => problem.fieldName === 'psrn')
                    : undefined
                }
                value={
                  participant?.psrn ? (
                    <CopiedText
                      feedback="ОГРН скопирован!"
                      text={participant.psrn}
                      title="Скопировать ОГРН"
                    />
                  ) : (
                    <NoDataMessage />
                  )
                }
              />
            </Typography>
          </Box>
        </Box>

        {participant?.ceo ? (
          <Box>
            <Typography sx={{ textAlign: 'center' }} variant="subtitle2">
              Генеральный директор
            </Typography>
            <Box sx={styles.table}>
              <Box sx={styles.tableRow}>
                <Typography sx={styles.tableKey} variant="body2">
                  Фамилия:
                </Typography>
                <Typography sx={styles.tableValue} variant="subtitle2">
                  <Field
                    problem={
                      !!participantProblems.length
                        ? participantProblems.find(
                            (problem) => problem.fieldName === 'ceo.lastName',
                          )
                        : undefined
                    }
                    value={
                      participant.ceo?.lastName ? (
                        <>{participant.ceo.lastName}</>
                      ) : (
                        <NoDataMessage />
                      )
                    }
                  />
                </Typography>
              </Box>

              <Box sx={styles.tableRow}>
                <Typography sx={styles.tableKey} variant="body2">
                  Имя:
                </Typography>
                <Typography sx={styles.tableValue} variant="subtitle2">
                  <Field
                    problem={
                      !!participantProblems.length
                        ? participantProblems.find(
                            (problem) => problem.fieldName === 'ceo.firstName',
                          )
                        : undefined
                    }
                    value={
                      participant.ceo?.firstName ? (
                        <>{participant.ceo.firstName}</>
                      ) : (
                        <NoDataMessage />
                      )
                    }
                  />
                </Typography>
              </Box>

              <Box sx={styles.tableRow}>
                <Typography sx={styles.tableKey} variant="body2">
                  Отчество:
                </Typography>
                <Typography sx={styles.tableValue} variant="subtitle2">
                  <Field
                    problem={
                      !!participantProblems.length
                        ? participantProblems.find(
                            (problem) => problem.fieldName === 'ceo.middleName',
                          )
                        : undefined
                    }
                    value={
                      participant.ceo?.middleName ? (
                        <>{participant.ceo.middleName}</>
                      ) : (
                        <NoDataMessage />
                      )
                    }
                  />
                </Typography>
              </Box>

              <Box sx={styles.tableRow}>
                <Typography sx={styles.tableKey} variant="body2">
                  Телефон:
                </Typography>
                <Typography sx={styles.tableValue} variant="subtitle2">
                  <Field
                    problem={
                      !!participantProblems.length
                        ? participantProblems.find((problem) => problem.fieldName === 'ceo.phone')
                        : undefined
                    }
                    value={
                      participant.ceo?.phone ? (
                        <>
                          <Phone phone={participant?.ceo?.phone} />
                        </>
                      ) : (
                        <NoDataMessage />
                      )
                    }
                  />
                </Typography>
              </Box>

              <Box sx={styles.tableRow}>
                <Typography sx={styles.tableKey} variant="body2">
                  E-mail:
                </Typography>
                <Typography sx={styles.tableValue} variant="subtitle2">
                  <Field
                    problem={
                      !!participantProblems.length
                        ? participantProblems.find((problem) => problem.fieldName === 'ceo.email')
                        : undefined
                    }
                    value={
                      participant.ceo?.email ? (
                        <Link href={`mailto:${participant.ceo.email}`}>
                          {participant.ceo.email}
                        </Link>
                      ) : (
                        <NoDataMessage />
                      )
                    }
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}
