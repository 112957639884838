import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { find, map } from 'lodash';
import { FormattedMessage } from "react-intl";

import { CATEGORY_DOCUMENTS } from "./constants";
import { SOURCE_DOCUMENTS_UPLOAD } from '../graphql/mutations/sourceDocumentsUpload';
import { GET_SOURCE_DOCUMENT_TYPES } from "../graphql/queries/sourceDocumentTypes";

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

FilesModal.propTypes = {
  files: PropTypes.array,
  refetchData: PropTypes.func,
}

export default function FilesModal({ files, refetchData }) {
  const [open, setOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState(files);
  const [uploadSourceDocuments, { loading }] = useMutation(
    SOURCE_DOCUMENTS_UPLOAD,
  );
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleUploadSourceDocuments = useCallback(() => {
    const filesToUpload = [...(files || [])];

    filesToUpload.forEach((file) => {
      Object.assign(file, { uploading: true });
      let variables = {
        file,
        isOriginalReceived: file.isOriginalReceived,
        sourceDocumentTypeId: file.documentType,
      }

      if ( file?.documentComment?.length > 0 ) {
        variables = {
          ...variables,
          comment: file?.documentComment
        }
      }

      uploadSourceDocuments({
        variables
      })
        .then(() => Object.assign(file, { uploaded: true, uploading: false }))
        .finally(() => {
          setAcceptedFiles([...filesToUpload]);
          handleClose();
          refetchData();
        });
    });
  }, [files, handleClose, uploadSourceDocuments, refetchData]);

  const handleOriginalCheck = useCallback((id) => (_) => {
    const updatedFiles = [...acceptedFiles].map((file) => (file.id === id)
      ? Object.assign(file, { isOriginalReceived: !file.isOriginalReceived })
      : file,
    );
    setAcceptedFiles(updatedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (!!files.length) {
      handleOpen();
      setAcceptedFiles(files);
    }
  }, [files, handleOpen]);

  const [fetchSourceDocumentTypes, { data: dataSourceDocumentTypes, loading: loadingSourceDocumentTypes }] = useLazyQuery(GET_SOURCE_DOCUMENT_TYPES);

  const [sourceDocumentTypes, setSourceDocumentTypes] = useState([]);

  useEffect(() => {
    if (dataSourceDocumentTypes?.sourceDocumentTypes?.length) {
      setSourceDocumentTypes(dataSourceDocumentTypes.sourceDocumentTypes)
    }
  }, [dataSourceDocumentTypes])

  const [disabledButtonUpload, setDisabledButtonUpload] = useState(true);

  const handleChangeSelect = useCallback((select, id) => (event) => {
    if (select === 'documentCategory') {
      fetchSourceDocumentTypes({
        variables: {
          filter: {
            categoryEq: event.target.value,
            assignableEq: true
          },
        }
      })
    }

    const updatedFiles = [...acceptedFiles].map((file) => (file.id === id)
      ? Object.assign(file, { [select]: event.target.value })
      : file,
    );

    setAcceptedFiles(updatedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    const f = find(acceptedFiles, (file) => !file.documentCategory || !file.documentType)

    setDisabledButtonUpload(!!f)
  }, [acceptedFiles])


  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogContent dividers>
        <Typography component="h2" sx={styles.title} variant="h2">
          {loading ? <>Загружаем документы...</> : <>Загрузка документов</>}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Typography>

        <TableContainer>
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Название</TableCell>
                <TableCell>Категория</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Оригинал</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedFiles.map((file) => (
                <TableRow
                  key={file.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {file.uploading
                      ? <CircularProgress />
                      : (
                        <>
                          {file.type === 'application/pdf'
                            ? <InsertDriveFileIcon />
                            : <img alt={file.name} src={file.preview} width="50px" />}
                        </>
                      )}
                  </TableCell>
                  <TableCell>
                    {file.uploaded && <CheckCircleIcon />}
                    {file.name}
                  </TableCell>
                  <TableCell sx={{ minWidth: "120px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-category-label">Категория</InputLabel>
                      <Select
                        id="select-category"
                        label="Категория"
                        labelId="select-category-label"
                        onChange={handleChangeSelect('documentCategory', file.id)}
                        value={file.documentCategory}
                      >
                        <MenuItem disabled>Укажите категорию</MenuItem>

                        {map(CATEGORY_DOCUMENTS, category => (
                          <MenuItem key={category} value={category}>
                            <FormattedMessage id={`incomingdocuments.category.${category}`} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl disabled={loadingSourceDocumentTypes} fullWidth>
                      <InputLabel id="select-type-label">Тип</InputLabel>
                      <Select
                        id="select-type"
                        label="Тип"
                        labelId="select-type-label"
                        onChange={handleChangeSelect('documentType', file.id)}
                        value={file.documentType}
                      >
                        <MenuItem disabled>Укажите тип</MenuItem>

                        {sourceDocumentTypes.map(({id, name}) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      multiline
                      onChange={handleChangeSelect('documentComment', file.id)}
                      placeholder={"Комментарий"}
                      value={file.comment}
                      sx={{ minWidth: '300px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={file.isOriginalReceived}
                      defaultChecked
                      onChange={handleOriginalCheck(file.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          disabled={loading || disabledButtonUpload}
          onClick={handleUploadSourceDocuments}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Загрузить
        </Button>
      </DialogActions>
    </Dialog>
  );
}