import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import download from "./img/download.svg";
import downloadP from "./img/downloadP.svg";

const MAX_SIZE_MB = 50;

function DropzoneImages({ accept, onFilesDropped, size }) {
  const { getInputProps, getRootProps } = useDropzone({
    accept: accept,
    maxSize: MAX_SIZE_MB * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      if (onFilesDropped instanceof Function) {
        onFilesDropped(
          acceptedFiles.map(
            (file) => Object.assign(file, {
              imageId: `create_${uuid()}`,
              preview: URL.createObjectURL(file),
              uploaded: false,
              uploading: true,
              isOriginalReceived: true,
            }),
          ),
        );
      }
    },
  });

  const styles = {
    DropZone: {
      position: 'sticky',
      bottom: 0,
      height: 139,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 20px',
      border: '2px dashed #3D6CE7',
      borderRadius: '16px',
      color: '#6E7884',
      cursor: 'pointer',
      transition: 'outline-offset .15s ease-in-out, background-color .15s linear',
    },
    titleDropZoneSmail: {
      color: '#3D6CE7',
      textAlign: 'center',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px'
    },
    img: {
      marginBottom: '21px',
    },
  };

  if (size === "middle") {
    styles.DropZone.width = "142px";
    styles.DropZone.borderRadius = "8px";
  } else if (size === "small") {
    styles.DropZone.width = "89px";
    styles.DropZone.height = "89px";
    styles.DropZone.borderRadius = "8px";
    styles.titleDropZoneSmail.fontSize = "12px";
    styles.titleDropZoneSmail.lineHeight = "14px";
    styles.img.width = "24px";
    styles.img.height = "24px";
    styles.img.marginBottom = "4px";
  } else {
    styles.DropZone.width = "100%";
  }

  return (
    <Box {...getRootProps({ sx: styles.DropZone })}>
      <input {...getInputProps()} />

      {size === "small" || size === "middle"  ? (
        <>
          <Box component={"img"} src={downloadP} sx={ styles.img } />

          <Typography component={'span'} sx={ styles.titleDropZoneSmail }>
            Выберите фото
          </Typography>
        </>
      ) : (
        <>
          <Box component={"img"} src={download} sx={ styles.img } />

          <Typography>
            Перетащите фотографии в это окно или
            <Typography component={'span'} sx={{ color: '#3D6CE7' }}>
              {' '}выберите файлы
            </Typography>
          </Typography>
        </>
      )}
    </Box >
  );
};

DropzoneImages.defaultProps = {
  accept: "image/*, .heic"
};

DropzoneImages.propTypes = {
  accept: PropTypes.any,
  onFilesDropped: PropTypes.func,
  size: PropTypes.string,
};

export default DropzoneImages;