/**
 *
 * TODO: Обрабатывать ошибку, если запрос не выполнился
 *
 * {"data":{"creditHistoryCurrentFetchProcess":{"id":"12","state":"FINISHED","createdAt":"2021-05-18T14:04:39+03:00","startedAt":"2021-05-18T14:04:39+03:00","failedAt":null,"finishedAt":"2021-05-18T14:04:39+03:00","resultData":{"status":3,"errorText":"Данные об адресе не заполнены","responseDate":"2021-05-18 14:04:39"},"verificationData":{"dateOfBirth":"1978-06-12","firstName":"Андрей","lastName":"Бурша","middleName":"Петрович","passportIssueDate":"2003-12-29","passportNumber":"653921","passportSeries":"3403","__typename":"CreditHistoryFetchProcessVerificationData"},"__typename":"CreditHistoryFetchProcess"}}}
 */

import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import _sortBy from 'lodash.sortby';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';
import {
  CREDIT_HISTORY_START_FETCH_PROCESS,
} from 'modules/contacts/graphql/mutations/creditHistoryStartFetchProcess';
import {
  CREDIT_HISTORY_CURRENT_FETCH_PROCESS,
} from 'modules/contacts/graphql/queries/creditHistoryCurrentFetchProcess';
import { customSort } from 'utils/customSort';
import { formatDate } from 'utils/dateTimeFormatters';

import TableContactCreditHistory from './TableContactCreditHistory';

const sortBy = ['активен', 'Продан', 'просрочен', 'закрыт'];

function calcDaysDiff(start, end) {
  if (start && end) {
    const startDateTime = DateTime.fromISO(start);
    const stopDateTime = DateTime.fromISO(end);

    const { days } = stopDateTime.diff(startDateTime, 'days');
    return Math.trunc(days);
  }
  return;
}

PageContactCreditHistory.propTypes = {
  contactId: PropTypes.string,
  historyId: PropTypes.string,
};

PageContactCreditHistory.defaultProps = {
  historyId: null,
};

export default function PageContactCreditHistory({ contactId, historyId }) {
  const [start] = useMutation(CREDIT_HISTORY_START_FETCH_PROCESS, {
    variables: {
      contactId,
    },
  });

  const { data, error, loading } = useQuery(
    CREDIT_HISTORY_CURRENT_FETCH_PROCESS,
    {
      variables: {
        contactId,
        id: historyId
      }
    }
  );

  if (error) {
    return (
      <>
        <Typography>{error.message}</Typography>
        <Typography>Обратитесь к администратору</Typography>
      </>
    );
  }

  if (loading) {
    return 'Загрузка...';
  }

  if (data.creditHistoryCurrentFetchProcess === null) {
    return (
      <>
        <Typography variant="h3">
          Кредитная история не запрашивалась
        </Typography>
        <Button
          onClick={() => {
            start();
          }}
        >
          Запросить в Equifax (40 руб!!)
        </Button>
      </>
    );
  }

  const {
    finishedAt,
    resultData: { bkiData, errorText, status },
    state,
    summary,
    verificationData,
  } = data.creditHistoryCurrentFetchProcess;

  if (status === 3 || errorText || state === 'FAILED') {
    return (
      <Box px={5}>
        <Typography gutterBottom variant="h2">
          {errorText || 'Произошла ошибка'}
        </Typography>
        <Typography>Обратитесь к администратору</Typography>
      </Box>
    );
  }

  return (
    <BlockCreditHistory
      {...{ bkiData, finishedAt, summary, verificationData }}
    />
  );
}

BlockCreditHistory.propTypes = {
  bkiData: PropTypes.object,
  finishedAt: PropTypes.any,
  summary: PropTypes.object,
  verificationData: PropTypes.object,
};
function BlockCreditHistory({
  bkiData,
  finishedAt,
  summary,
  verificationData,
}) {
  const diff = calcDaysDiff(finishedAt, DateTime.local().toISODate());

  const sortedCreditGroups = useMemo(() => {
    if (bkiData.credits) {
      const sortedCredits = customSort({
        data: _sortBy(bkiData.credits, 'creditDatabeg').map((item) => ({
          ...item,
          orderField: sortBy.includes(item.creditStatusText)
            ? item.creditStatusText
            : 'other',
        })),
        sortBy: [...sortBy, 'other'],
        sortField: 'orderField',
      });

      return sortedCredits;
    } else {
      return [];
    }
  }, [bkiData]);

  const [ totalMaxOverdueDebt, setTotalMaxOverdueDebt ] = useState(0);

  useEffect(() => {
    let total = 0;

    bkiData.credits && bkiData.credits.forEach(credit => {
      if (credit?.maxOverdueDebt > total) {
        total = credit.maxOverdueDebt;
      }
    });

    setTotalMaxOverdueDebt(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SummaryWidget = (
    <Box sx={{ border: '8px solid whitesmoke', borderRadius: '8px' }}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h3">Кредиты</Typography>
          <Box sx={{ textAlign: 'right', ml: 'auto' }}>
            <Typography
              color="textSecondary"
              component="span"
              variant="caption"
            >
              активные / всего&nbsp;&nbsp;&nbsp;
            </Typography>
            <Typography component="span" variant="h4">
              <b>{summary.activeCreditsCount}</b> / {bkiData.numCredits}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto auto auto',
            gridTemplateColumns: 'auto auto',
            gridRowGap: 16,
            gridColumnGap: 40,
            alignItems: 'baseline',
            gridAutoFlow: 'column',
          }}
        >
          <Box>
            <Typography color="textSecondary" variant="caption">
              Сумма активных кредитов
            </Typography>
            <Typography color="warning.dark" sx={{ fontWeight: 600 }}>
              <FormattedCurrency
                maximumFractionDigits={0}
                value={summary.activeCreditsSum}
              />
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              Сумма закрытых кредитов
            </Typography>
            <Typography color="textSecondary" sx={{ fontWeight: 600 }}>
              <FormattedCurrency
                maximumFractionDigits={0}
                value={summary.endedCreditsSum}
              />
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              Сумма проданных кредитов
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              <FormattedCurrency
                maximumFractionDigits={0}
                value={summary.soldCreditsSum}
              />
            </Typography>
          </Box>

          <Box>
            <Typography color="textSecondary" variant="caption">
              Ежемесячные платежи
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              <FormattedCurrency
                maximumFractionDigits={0}
                value={summary.currentMonthlyPayments}
              />
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              Макс. просрочка в рублях
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              <FormattedCurrency
                maximumFractionDigits={0}
                placeholder="---"
                value={totalMaxOverdueDebt}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const RequestInfoWidget = (
    <Box
      sx={{
        border: '8px solid whitesmoke',
        borderRadius: '8px',
        background: 'whitesmoke',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box>
          <Typography variant="h3">Данные запроса</Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto auto auto',
            gridTemplateColumns: 'auto',
            gridRowGap: 16,
            gridColumnGap: 40,
            alignItems: 'baseline',
          }}
        >
          <Box>
            <Typography color="textSecondary" variant="caption">
              ФИО
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {verificationData.lastName} {verificationData.firstName} {verificationData.middleName}
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              Дата рождения
            </Typography>
            <Typography>{formatDate(verificationData.dateOfBirth)}</Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              Паспорт
            </Typography>
            <Typography>
              {verificationData.passportSeries}&nbsp;
              {verificationData.passportNumber} от {formatDate(verificationData.passportIssueDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box mt={3} pb={8} px={4}>
      <Typography gutterBottom variant="h2">
        Кредитная история от {formatDate(finishedAt)}
      </Typography>

      <Typography
        sx={{
          fontWeight: 500,
          color: () => {
            if (diff > 60) return 'error.dark';
            if (diff < 30) return 'info.dark';
            return 'warning.dark';
          },
        }}
        variant="body2"
      >
        Последнее обновление <FormattedMessage id="daysAgo.plural" values={{ amount: diff }} />
      </Typography>
      <Box sx={{ display: 'flex', my: 5, justifyContent: 'space-between' }}>
        {SummaryWidget}
        {RequestInfoWidget}
      </Box>
      <TableContactCreditHistory data={sortedCreditGroups} />
    </Box>
  );
}
